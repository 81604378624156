import { AdminLayout } from '../../../src/base/components';
import { EditMemberContainer } from '../../../src/base/containers/members/EditContainer';

export const EditMember = () => {
  return (
    <AdminLayout>
      <EditMemberContainer />
    </AdminLayout>
  );
};
