import { AdminLayout } from '../../../src/base/components/AdminLayout';
import { SettingContainer } from '../../../src/base/containers/settings/Container';

export const Setting = () => {
  return (
    <AdminLayout>
      <SettingContainer />
    </AdminLayout>
  );
};
