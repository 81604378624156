import { AdminLayout } from '../../../src/base/components';
import { MemberCreateContainer } from '../../../src/base/containers/members/CreateContainer';

export const CreateMember = () => {
  return (
    <AdminLayout>
      <MemberCreateContainer />
    </AdminLayout>
  );
};
